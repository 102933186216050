import axios from 'axios'
import { CredentialDetailsEmail, CredentialDetailsMobile, PartialUserUpdate, User, UserLoggedIn } from '../types/types'
import Instance from '../utils/axios'
import Crudable from './crudable'

function isUserObject(user: any): user is User {
	if (!user) return false
	return 'id' in user && 'email' in user && 'firstName' in user && 'lastName' in user
}

const networkErrorMessage = 'There was an error with your connection, please try again'

class AuthService implements Crudable<UserLoggedIn | User> {
	createOne = async (
		firstName: string,
		lastName: string,
		birthday: string,
		email: string,
		code: string,
		password: string,
		confirmPassword: string,
		token: string
	): Promise<UserLoggedIn> => {
		// register a user

		try {
			const result = await Instance.post(
				'auth/register',
				{
					firstName,
					lastName,
					birthday,
					email,
					code,
					password,
					confirmPassword,
				},
				{ headers: { Authorization: `Bearer ${token}` } }
			)

			let user = result.data.user as User

			if (!isUserObject(result.data.user)) {
				throw Error('Malformed user return data')
			}

			return {
				accessToken: {
					token: result.data.accessToken.token,
					expiresIn: result.data.accessToken.expiresIn,
				},
				user,
			}
		} catch (error) {
			if (error && axios.isAxiosError(error)) {
				if (error?.code === 'ERR_NETWORK' || error?.code === 'ECONNABORTED') throw Error(networkErrorMessage)
			}

			throw Error('Something went wrong, double check your details and try again')
		}
	}

	getOne = async (): Promise<User> => {
		// get a user's details

		throw Error('Not Implemented')
	}

	updateOne = async (partialUser: PartialUserUpdate, userId: string) => {
		try {
			await Instance.patch(`users/${userId}`, partialUser)
		} catch (error) {
			if (error && axios.isAxiosError(error)) {
				if (error?.code === 'ERR_NETWORK' || error?.code === 'ECONNABORTED') throw Error(networkErrorMessage)
			}

			throw Error('Something went wrong, double check your details and try again')
		}
	}

	deleteOne = async (userId: string) => {
		try {
			await Instance.delete(`users/${userId}`)
		} catch (error) {
			if (error && axios.isAxiosError(error)) {
				if (error?.code === 'ERR_NETWORK' || error?.code === 'ECONNABORTED') throw Error(networkErrorMessage)
			}

			throw Error('Something went wrong, please contact an admin to delete your account')
		}
	}

	updatePassword = async (
		credentialDetails: CredentialDetailsMobile | CredentialDetailsEmail,
		code: string,
		password: string,
		confirmPassword: string
	): Promise<boolean> => {
		try {
			await Instance.put('users/password', {
				...credentialDetails,
				code,
				password,
				confirmPassword,
			})

			return true
		} catch (error) {
			if (error && axios.isAxiosError(error)) {
				if (error?.code === 'ERR_NETWORK' || error?.code === 'ECONNABORTED') throw Error(networkErrorMessage)
			}

			throw Error('Invalid password')
		}
	}

	loginSMS = async (credentialDetails: CredentialDetailsEmail | CredentialDetailsMobile, code: string): Promise<UserLoggedIn> => {
		try {
			const result = await Instance.post('auth/login/sms', {
				...credentialDetails,
				code,
			})

			let user = result.data.user as User

			if (!isUserObject(result.data.user)) {
				throw Error('Malformed user return data')
			}

			return {
				accessToken: {
					token: result.data.accessToken.token,
					expiresIn: result.data.accessToken.expiresIn,
				},
				user,
			}
		} catch (error) {
			if (error && axios.isAxiosError(error)) {
				if (error?.code === 'ERR_NETWORK' || error?.code === 'ECONNABORTED') throw Error(networkErrorMessage)
			}

			throw Error('Incorrect/expired code')
		}
	}

	loginEmail = async (email: string, password: string): Promise<UserLoggedIn> => {
		try {
			const result = await Instance.post('auth/login/email', {
				email,
				password,
			})

			let user = result.data.user as User

			if (!isUserObject(result.data.user)) {
				throw Error('Malformed user return data')
			}

			return {
				accessToken: {
					token: result.data.accessToken.token,
					expiresIn: result.data.accessToken.expiresIn,
				},
				user,
			}
		} catch (error) {
			if (error && axios.isAxiosError(error)) {
				if (error?.code === 'ERR_NETWORK' || error?.code === 'ECONNABORTED') throw Error(networkErrorMessage)
			}

			throw Error('Invalid email or password')
		}
	}

	static getMe = async (): Promise<UserLoggedIn | undefined> => {
		// get currently logged in user

		try {
			const result = await Instance.get('users/me')

			let user = result.data as User

			if (!isUserObject(result.data)) {
				throw Error('Malformed user return data')
			}

			return {
				accessToken: {
					token: '',
					expiresIn: '',
				},
				user,
			}
		} catch (error) {
			if (error && axios.isAxiosError(error)) {
				if (error?.code === 'ERR_NETWORK' || error?.code === 'ECONNABORTED') throw Error(networkErrorMessage)
			}

			throw Error('User does not exist')
		}
	}
}

export default AuthService
