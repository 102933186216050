import { ReactNode, useState } from 'react'
import LoginMain from '../assets/images/LoginMain.png'
import Button from '../components/core/button'
import { useFormik } from 'formik'
import VerificationCode from '../components/verficiation-code'
import { useNavigate } from 'react-router-dom'
import PhoneValidationInput from '../components/core/phone-validation-input'
import { loginMobileSchema } from '../utils/validation-schemas'
import LoginEmail from '../components/login-email'
import AuthService from '../services/auth'
import useGlobalState from '../utils/use-global-state'
import { CredentialDetailsEmail, CredentialDetailsMobile, VerificationCodeOperation } from '../types/types'
import asyncTimeout from '../utils/asyncTimeout'
import parseMobileNumber from '../utils/parse-mobile-number'
import errorPopupParser from '../utils/error-popup-parser'
import VerificationService from '../services/verification'
import Onboard from '../components/onboard'
import Logo from '../assets/images/WHMLogo.jpg'
import Arrow from '../assets/icons/Arrow'

export type LoginPageNames = 'ONBOARD' | 'MOBILE_LOGIN' | 'EMAIL_LOGIN' | 'VERIFY_CODE' | 'MOBILE_REGISTER'

function Login() {
	const [page, setPage] = useState<LoginPageNames>('ONBOARD')
	const [userVerificationOperation, setUserVerificationOperation] = useState<VerificationCodeOperation>()
	const [token, setToken] = useState<string>('')
	const [isLoading, setIsLoading] = useState(false)

	const navigate = useNavigate()
	const auth = new AuthService()
	const verify = new VerificationService()
	const { dispatch } = useGlobalState()

	const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
		initialValues: {
			phoneNumber: '',
			countryCode: {
				countryCode: 'au',
				dialCode: '61',
				format: '+.. (..) .... ....',
				name: 'Australia',
			},
		},
		onSubmit: (values) => {
			sendVerificationCode(parseMobileNumber(values.phoneNumber, values.countryCode.countryCode), values.countryCode.dialCode)
		},
		validationSchema: loginMobileSchema,
	})

	const sendVerificationCode = async (number: string, countryCode: string) => {
		setIsLoading(true)

		const credentialDetails: CredentialDetailsEmail | CredentialDetailsMobile = {
			number,
			countryCode,
		}

		try {
			const result = await verify.sendVerificationCode(credentialDetails)
			if (result.type === 'register') {
				setUserVerificationOperation('register')
				setPage('VERIFY_CODE')
				setToken(result.data.response.data.data.token)
			} else {
				setUserVerificationOperation('login')
				setPage('VERIFY_CODE')
				setToken(result.data.response.data.token)
			}
		} catch (error) {
		} finally {
			setIsLoading(false)
		}
	}

	const loginWithCode = async (code: string) => {
		const credentialDetails: CredentialDetailsEmail | CredentialDetailsMobile = {
			number: parseMobileNumber(values.phoneNumber, values.countryCode.countryCode),
			countryCode: values.countryCode.dialCode,
		}

		try {
			const [loginResult] = await Promise.all([auth.loginSMS(credentialDetails, code), asyncTimeout(800)])

			dispatch({ type: 'setUser', data: loginResult.user })
			localStorage.setItem('WHMToken', loginResult.accessToken.token)

			navigate('/')
		} catch (error) {
			errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
		}
	}

	const registerWithCode = async (code: string) => {
		if (!userVerificationOperation) {
			setPage('MOBILE_LOGIN')
			dispatch({
				type: 'setSnack',
				data: {
					isOpen: true,
					severity: 'error',
					message: 'There was an issue verifying your code, please try again',
				},
			})
			return
		}

		setIsLoading(true)

		const credentialDetails: CredentialDetailsEmail | CredentialDetailsMobile = {
			number: parseMobileNumber(values.phoneNumber, values.countryCode.countryCode),
			countryCode: values.countryCode.dialCode,
		}

		try {
			await Promise.all([verify.checkCode(credentialDetails, code, userVerificationOperation), asyncTimeout(800)])

			navigate('/register', { state: { code, token } })
		} catch (error) {
			let message
			if (error instanceof Error) message = error.message
			else message = String(error)
			dispatch({
				type: 'setSnack',
				data: {
					isOpen: true,
					severity: 'error',
					message: message,
				},
			})
		} finally {
			setIsLoading(false)
		}
	}

	const verifyCode = async (code: string) => {
		if (userVerificationOperation === 'login') loginWithCode(code)
		else if (userVerificationOperation === 'register') registerWithCode(code)
	}

	const renderMobileRegister = (): ReactNode => {
		return (
			<>
				<img src={LoginMain} className='' alt='' />
				<div className='px-4'>
					<div className='text-[30px] font-bold text-black-base mb-2 leading-[35px]'>Welcome to the Womens Health Movement</div>
					<div className='text-[18px] text-[#333333CC] mb-4 leading-5'>
						The place to change your lifestyle and habits alongside a group of like-minded women.
					</div>
					<div className='text-[18px] text-[#333333CC] leading-5 mb-4'>Enter your mobile phone number below to begin!</div>

					<PhoneValidationInput
						phoneValue={values.phoneNumber}
						setFieldValue={setFieldValue}
						phoneError={errors.phoneNumber}
						touched={touched.phoneNumber}
					/>

					<Button text={`Let's Go!`} type='submit' onClick={handleSubmit} className='' />

					<p className='text-[12px] text-black-light leading-5 mb-8'>
						By entering in your phone number you agree to receive a text message from Womens Health Movement
					</p>

					<div className='text-[#33333399] text-[14px] text-center opacity-80 mb-6'>
						Have an account?{' '}
						<span className='text-purple-base font-bold cursor-pointer' onClick={() => setPage('MOBILE_LOGIN')}>
							Sign in here!
						</span>
					</div>
				</div>
			</>
		)
	}

	const renderMobileLogin = (): ReactNode => {
		return (
			<>
				<div className='px-4 h-screen md:h-auto flex flex-col select-none'>
					<div className='flex items-center relative justify-center mb-12 w-full mt-8'>
						<Arrow className='absolute left-0' onClick={() => setPage('ONBOARD')} />
						<img src={Logo} className='h-5' alt='WHM Logo' />
					</div>
					<div className='text-[30px] font-bold text-black-base mb-2 leading-[35px]'>Welcome Back!</div>
					<div className='text-[16px] text-[#333333CC] mb-4 leading-5'>Sign back into your account with your mobile number</div>

					<PhoneValidationInput
						phoneValue={values.phoneNumber}
						setFieldValue={setFieldValue}
						phoneError={errors.phoneNumber}
						touched={touched.phoneNumber}
					/>

					<p className='text-[12px] text-black-light leading-5 mb-8'>
						By entering in your phone number you agree to receive a text message from Womens Health Movement
					</p>

					<div className='text-[#33333399] text-[14px] text-center mb-6 mt-auto'>
						<Button
							text={`Sign in with email`}
							onClick={() => setPage('EMAIL_LOGIN')}
							className='bg-white text-gold-base hover:text-gold-base hover:bg-white mb-4'
						/>
						<Button text={`Sign in`} type='submit' onClick={handleSubmit} className='' />
					</div>
				</div>
			</>
		)
	}

	const renderSwitch = (): ReactNode => {
		switch (page) {
			case 'ONBOARD':
				return <Onboard setPage={setPage} />
			case 'EMAIL_LOGIN':
				return <LoginEmail setPage={setPage} />
			case 'MOBILE_REGISTER':
				return renderMobileRegister()
			case 'MOBILE_LOGIN':
				return renderMobileLogin()
			case 'VERIFY_CODE':
				return (
					<VerificationCode
						source={values.phoneNumber}
						verifyCode={verifyCode}
						isLoading={isLoading}
						backHandler={() => setPage('MOBILE_REGISTER')}
						resendCode={() =>
							sendVerificationCode(parseMobileNumber(values.phoneNumber, values.countryCode.countryCode), values.countryCode.dialCode)
						}
					/>
				)
		}
	}

	return <div className='h-full'>{renderSwitch()}</div>
}

export default Login
