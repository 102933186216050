import { HTMLInputTypeAttribute, useState } from 'react'
import Arrow from '../assets/icons/Arrow'
import ShowPassword from '../assets/icons/ShowPassword'
import ValidationInput from './core/validation-input'
import Button from './core/button'
import { useFormik } from 'formik'
import { loginEmailSchema } from '../utils/validation-schemas'
import Logo from '../assets/images/WHMLogo.jpg'
import { LoginPageNames } from '../pages/login'
import { useNavigate } from 'react-router-dom'
import AuthService from '../services/auth'
import asyncTimeout from '../utils/asyncTimeout'
import useGlobalState from '../utils/use-global-state'
import errorPopupParser from '../utils/error-popup-parser'

type Props = {
	setPage: (pageName: LoginPageNames) => void
}

function LoginEmail({ setPage }: Props) {
	const [passwordInputType, setPasswordInputType] = useState<HTMLInputTypeAttribute>('password')
	const [isLoading, setIsLoading] = useState(false)

	const navigate = useNavigate()
	const auth = new AuthService()

	const { dispatch } = useGlobalState()

	const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		onSubmit: (values) => {
			loginUserWithEmail(values.email, values.password)
		},
		validationSchema: loginEmailSchema,
	})

	const loginUserWithEmail = async (email: string, password: string) => {
		setIsLoading(true)
		try {
			const [loginResult] = await Promise.all([auth.loginEmail(email, password), asyncTimeout(800)])

			dispatch({ type: 'setUser', data: loginResult.user })
			localStorage.setItem('WHMToken', loginResult.accessToken.token)

			navigate('/')
		} catch (error) {
			errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<div className='px-4 py-8 h-screen md:h-auto flex flex-col select-none'>
			<div className='flex items-center justify-center relative mb-12 w-full'>
				<Arrow className='absolute left-0' onClick={() => setPage('ONBOARD')} />
				<img src={Logo} className='h-5' alt='WHM Logo' />
			</div>

			<div className='text-[30px] font-bold text-black-base mb-2 leading-[35px]'>Sign In</div>
			<div className='text-[18px] text-black-light leading-5 mb-6'>Sign in to your WHM account using your email and password.</div>

			<ValidationInput
				placeholder='EMAIL'
				className='mb-2'
				error={errors.email}
				touched={touched.email}
				value={values.email}
				onChange={(e) => setFieldValue('email', e.target.value)}
			/>

			<ValidationInput
				inputType={passwordInputType}
				placeholder='PASSWORD'
				className='mb-4'
				error={errors.password}
				touched={touched.password}
				value={values.password}
				onChange={(e) => setFieldValue('password', e.target.value)}
				icon={<ShowPassword onClick={() => setPasswordInputType(passwordInputType === 'password' ? 'text' : 'password')} />}
			/>

			<div className='text-[18px] text-black-light leading-5 mb-6 cursor-pointer font-bold w-fit' onClick={() => navigate('/forgot-password')}>
				Forgot your password?
			</div>

			<div className='mt-auto'>
				<Button
					text={`Sign in with mobile`}
					onClick={() => setPage('MOBILE_LOGIN')}
					className='bg-white text-gold-base hover:text-gold-base hover:bg-white mb-4'
				/>
				<Button text='Sign In' onClick={handleSubmit} isLoading={isLoading} />
			</div>
		</div>
	)
}

export default LoginEmail
